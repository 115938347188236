import { DateUtils } from "react-day-picker";
import { format } from "date-fns";
import { pt_br } from "../componentes/Basicos/funcoes";

//Cria um array com as datas de domingo à sábado a partir de uma data pertencente a semana
export const semanaAPartirDeUmaData = data => {
    let semana = [];

    if (!DateUtils.isDate(data))
        return semana;

    let primeiroDiaDaSemana = subtraiOuAdicionaDiasDeUmaData(data, -data.getDay());

    for (let dia = 0; dia < 7; dia++)
        semana.push(
            subtraiOuAdicionaDiasDeUmaData(primeiroDiaDaSemana, dia)
        );

    return semana;
}

export const primeiraSemanaPossivelDoMes = ({ mes, dataInicial }) => {
    let dataInicioDoMes = new Date(dataInicial.getFullYear(), mes);

    if (mes === dataInicial.getMonth())
        dataInicioDoMes.setDate(dataInicial.getDate());

    return semanaAPartirDeUmaData(dataInicioDoMes);
}

export const subtraiOuAdicionaDiasDeUmaData = (data, quatidadeDeDias) => {
    let novaData = new Date(data);
    novaData.setDate(novaData.getDate() + quatidadeDeDias);

    return novaData;
}

//  'yyyy-MM-dd' => 2018-12-25
//  'dd/MM' => 30/12
export const transformaDataEmString = (data, formato) => format(data, formato);

export const mesesDaSemana = semana => {
    let meses = [];

    if (semana.length === 0)
        return meses;

    let primeiroMes = semana[0].getMonth();
    let segundoMes = semana[6].getMonth()

    if (primeiroMes === segundoMes) {
        meses.push(primeiroMes);
        return meses;
    }

    meses.push(primeiroMes);
    meses.push(segundoMes);

    return meses;
}

export const mesesDaSemanaFormatado = semana => {
    let meses = mesesDaSemana(semana);

    //Uma semana pode ter um mês ou dois
    if (meses.length === 0)
        return "";
    if (meses.length === 1)
        return `${pt_br.months[meses[0]]}`;
    if (meses.length === 2)
        return `${pt_br.months[meses[0]]}-${pt_br.months[meses[1]]}`;
}

export const dataDeUmaSemanaEmString = ({ semana, diaDaSemana }) =>
    transformaDataEmString(semana[diaDaSemana], 'yyyy-MM-dd');

export const stringEmDate = dataString => new Date(dataString.split('-'));

export const dataInicial = ({ dataInicio }) => new Date(dataInicio?.split('-'));

export const dataInicialPeriodo = ({ periodoLetivo }) => new Date(periodoLetivo?.inicio?.split('-'));

export const dataFinal = ({ dataFim }) => new Date(dataFim?.split('-'));

export const dataFimPeriodo = ({ periodoLetivo }) => new Date(periodoLetivo?.fim?.split('-'));

export const dataInicialOfertaPeriodo = ofertaPeriodo => new Date(ofertaPeriodo.data_inicio.split('-'));

const diaÉValido = ({ data, periodoLetivo, dataInicio, dataFim }) => {
    if (dataInicio == undefined && dataFim != undefined)
        if (data < dataInicialPeriodo({ periodoLetivo }) || data > dataFinal({ dataFim }))
            return false;
    if (dataInicio != undefined && dataFim == undefined)
        if (data < dataInicial({ dataInicio }) || data > dataFimPeriodo({ periodoLetivo }))
            return false;
    if (dataInicio == undefined && dataFim == undefined)
        if (data < dataInicialPeriodo({ periodoLetivo }) || data > dataFimPeriodo({ periodoLetivo }))
            return false;
    return true;
}

export const semanaTemDiaValido = ({ semana, periodoLetivo, dataInicio, dataFim }) => semana.some(data => diaÉValido({ data, periodoLetivo, dataInicio, dataFim }));

export const semanaInicialDoPeriodo = ({ periodoLetivo }) => semanaAPartirDeUmaData(dataInicialPeriodo({ periodoLetivo }));

export const semanaInicialDoPeriodoData = ({ dataInicio }) => semanaAPartirDeUmaData(new Date(dataInicio?.split('-')));

export const avancarSemana = ({ semana, periodoLetivo, dataInicio, dataFim }) => {
    let ultimoDiaDaSemana = semana[6];
    let proximoDia = subtraiOuAdicionaDiasDeUmaData(ultimoDiaDaSemana, +1);
    let proximaSemana = semanaAPartirDeUmaData(proximoDia);

    if (semanaTemDiaValido({ semana: proximaSemana, periodoLetivo, dataInicio, dataFim }))
        return proximaSemana;
    return semana;
}

export const retrocederSemana = ({ semana, periodoLetivo, dataInicio, dataFim }) => {
    let primeroDiaDaSemana = semana[0];
    let diaAnterior = subtraiOuAdicionaDiasDeUmaData(primeroDiaDaSemana, -1);
    let semanaAnterior = semanaAPartirDeUmaData(diaAnterior);

    if (semanaTemDiaValido({ semana: semanaAnterior, periodoLetivo, dataInicio, dataFim }))
        return semanaAnterior;
    return semana;
}

export const avancarMes = ({ semana, periodoLetivo, dataInicio, dataFim }) => {
    let mesesSemana = mesesDaSemana(semana);
    let mes = mesesSemana.length === 2 ? mesesSemana[1] + 1 : mesesSemana[0] + 1;
    if (dataInicio != undefined)
        var primeiraSemana = primeiraSemanaPossivelDoMes({ mes, dataInicial: new Date(dataInicio?.split('-')) });
    else
        var primeiraSemana = primeiraSemanaPossivelDoMes({ mes, dataInicial: dataInicialPeriodo({ periodoLetivo }) });

    if (semanaTemDiaValido({ semana: primeiraSemana, periodoLetivo, dataInicio, dataFim }))
        return primeiraSemana;
    return semana;
}

export const retrocederMes = ({ semana, periodoLetivo, dataInicio, dataFim }) => {
    let mesesSemana = mesesDaSemana(semana);
    let mes = mesesSemana[0] - 1;

    console.log(periodoLetivo)
    
    if (dataInicio != undefined)
        var primeiraSemana = primeiraSemanaPossivelDoMes({ mes, dataInicial: new Date(dataInicio?.split('-')) });
    else
        var primeiraSemana = primeiraSemanaPossivelDoMes({ mes, dataInicial: dataInicialPeriodo({ periodoLetivo }) });

    if (semanaTemDiaValido({ semana: primeiraSemana, periodoLetivo, dataInicio, dataFim }))
        return primeiraSemana;
    return semana;
}

export const diaDaSemanaDaDataString = dataString => stringEmDate(dataString).getDay().toString();

export const semanasEntreDataInicialEDataFinal = ({ dataInicial, dataFinal }) => {
    let semanas = [];
    let semanaInicial = semanaAPartirDeUmaData(dataInicial);
    semanas.push(semanaInicial);

    let proxSemana = proximaSemana({ semana: semanaInicial });

    while (semanaTemDataValida({ semana: proxSemana, dataInicial, dataFinal })) {
        semanas.push(proxSemana);
        proxSemana = proximaSemana({ semana: proxSemana });
    }

    return semanas;
}

const proximaSemana = ({ semana }) => {
    let ultimoDiaDaSemana = semana[6];
    let proximoDia = subtraiOuAdicionaDiasDeUmaData(ultimoDiaDaSemana, +1);
    return semanaAPartirDeUmaData(proximoDia);
}

const semanaTemDataValida = ({ semana, dataInicial, dataFinal }) =>
    semana.some(data => dataEstáEntreDataInicialEDataFinal({ data, dataInicial, dataFinal }));

const dataEstáEntreDataInicialEDataFinal = ({ data, dataInicial, dataFinal }) =>
    data >= dataInicial && data <= dataFinal;

// export const calendarioOfertaService = {
//     semanasEntreDataInicialEDataFinal: ({dataInicial, dataFinal}) => {
//         let semanas = [];
//         let semanaInicial = semanaAPartirDeUmaData(dataInicial);
//         semanas.push(semanaInicial);

//         let proxSemana = proximaSemana({semana: semanaInicial});

//         while (semanaTemDataValida({semana: proxSemana, dataInicial, dataFinal})) {
//             semanas.push(proxSemana);
//             proxSemana = proximaSemana({semana: proxSemana});
//         }

//         return semanas;
//     },
// };

export function calendarioOfertaService({
    dataInicial,
    dataFinal
}) {
    const semanaAPartirDeUmaData = data => {
        let semana = [];

        if (!DateUtils.isDate(data))
            return semana;

        let primeiroDiaDaSemana = subtraiOuAdicionaDiasDeUmaData(data, -data.getDay());

        for (let dia = 0; dia < 7; dia++)
            semana.push(
                subtraiOuAdicionaDiasDeUmaData(primeiroDiaDaSemana, dia)
            );

        return semana;
    };

    const subtraiOuAdicionaDiasDeUmaData = (data, quatidadeDeDias) => {
        let novaData = new Date(data);
        novaData.setDate(novaData.getDate() + quatidadeDeDias);

        return novaData;
    };

    const proximaSemana = semana => {
        let ultimoDiaDaSemana = semana[6];
        let proximoDia = subtraiOuAdicionaDiasDeUmaData(ultimoDiaDaSemana, +1);
        return semanaAPartirDeUmaData(proximoDia);
    };

    const dataEstáEntreDataInicialEDataFinal = data =>
        data >= dataInicial && data <= dataFinal;

    const semanaTemDataValida = semana =>
        semana.some(data => dataEstáEntreDataInicialEDataFinal(data));

    const semanasEntreDataInicialEDataFinal = () => {
        let semanas = [];
        let semanaInicial = semanaAPartirDeUmaData(dataInicial);
        semanas.push(semanaInicial);

        let proxSemana = proximaSemana(semanaInicial);

        while (semanaTemDataValida(proxSemana)) {
            semanas.push(proxSemana);
            proxSemana = proximaSemana(proxSemana);
        }

        return semanas;
    }

    return {
        semanasEntreDataInicialEDataFinal,
    };

}

// let service = service({});
