import React, { PureComponent, Fragment } from 'react'
import { Card, Row, CardHeader, CardBody, Collapse, Button } from "reactstrap"
import PropTypes from 'prop-types'

const CardHoc = (ComponentWrap) => {
    class HOCcomponent extends PureComponent {
        constructor(props) {
            super(props)
            this.state = { collapse: this.props.collapse ? false : true }
        }

        toggleCollapse = () => this.setState((prevState) => ({ collapse: !prevState.collapse }))

        render() {
            return (
                <Card className={this.props.exibirCalendario ? this.props.className : 'sem-consulta'} data-title={this.props.title}>
                    {this.props.header
                        && <CardHeader className={(this.props.header) ? "card-subFuncionalidades cursor-ponteiro" : "card-subFuncionalidades"}
                            onClick={this.toggleCollapse}
                            style={{ lineHeight: (this.props.buttonHeader || this.props.buttonCrud) ? 1.7 : 1.2 }}
                            tag="h5"
                        >
                            {this.props.tituloHeader}

                            {this.props.buttonCrud
                                && <Fragment>
                                    <Button onClick={this.props.buttonHeaderEditarOnClick}
                                        className="btnHeader float-right">
                                        Editar
                                    </Button>
                                    <Button onClick={this.props.buttonHeaderDeletarOnClick}
                                        className="btnHeader float-right">
                                        Deletar
                                    </Button>
                                </Fragment>
                            }

                            {this.props.buttonHeader
                                && <Button onClick={(e) => { e.stopPropagation(); this.props.buttonHeaderOnClick() }}
                                    className="btnHeader float-right">
                                    {this.props.buttonLabel}
                                </Button>
                            }
                        </CardHeader>
                    }
                    {this.props.collapse
                        ? <Collapse isOpen={this.state.collapse}>
                            <CardBody className={'h-100 ' + this.props.classNameBody}>
                                <Row className='h-100'>
                                    <ComponentWrap {...this.props} ref={this.props.innerRef} collapse={this.state.collapse} />
                                </Row>
                            </CardBody>
                        </Collapse>

                        : <CardBody className={'h-100 ' + this.props.classNameBody}>
                            <Row className='h-100'>
                                <ComponentWrap {...this.props} ref={this.props.innerRef} collapse={this.state.collapse} />
                            </Row>
                        </CardBody>
                    }
                </Card>
            )
        }
    }

    HOCcomponent.propTypes = {
        classNavBar: PropTypes.string,
        titulo: PropTypes.string,
        inputChange: PropTypes.func,
        radioChange: PropTypes.func,
        className: PropTypes.string,
        collapse: PropTypes.bool, // usado para criar um card com body collapsed, sendo aberto quando clica no header
        header: PropTypes.bool, // Para gerar um header no card
        tituloHeader: PropTypes.string, // usado para definir a label do header
        buttonHeader: PropTypes.bool, // usado para criar um botao normal que estará no header
        buttonLabel: PropTypes.string, // usado em conjunto com buttonHeader, porem em caso onde so existe um unico buttom
        buttonCrud: PropTypes.bool, // usado para criar os botoes edit e deletar
        buttonHeaderOnClick: PropTypes.func, //funcao chamada em caso de um unico button 
        buttonHeaderDeletarOnClick: PropTypes.func, //funcao chamada em caso de um crud button, nessa é para delatar
        buttonHeaderEditarOnClick: PropTypes.func,  //funcao chamada em caso de um crud button, nessa é para editar
    }
    return HOCcomponent
}

export default CardHoc