import React, { useState, useEffect, Fragment } from 'react';
import { Col, Label, FormFeedback, FormGroup } from "reactstrap";
import PropTypes from 'prop-types';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { FaRegCalendarAlt } from 'react-icons/fa';
import dateFnsFormat from 'date-fns/format';
import addDays from 'date-fns/addDays';
import { formatoData, formatLaravelTimestamp, formatLaravelDate, parseDate, formatDate, pt_br } from '../funcoes';

const DateForm = (props) => {
    const [from, setFrom] = useState(parseDate(props.dataInicial.valorPadrao, formatLaravelTimestamp));
    const [to, setTo] = useState(parseDate(props.dataFinal ? props.dataFinal.valorPadrao : undefined, formatLaravelTimestamp));
    const [manipuladoPeloUsuario, setManipuladoPeloUsuario] = useState(false);
    const [manipuladoPeloUsuarioTo, setManipuladoPeloUsuarioTo] = useState(false);
    const [telaAgenda] = useState(props?.style?.telaAgenda ?? false);
    const [telaAvaliacao] = useState(props?.style?.telaAvaliacao ?? false);

    useEffect(() => {
        if (props.valorInicial == undefined) {
            setFrom(undefined)
        }
    }, [props.valorInicial]);

    useEffect(() => {
        if (props.valorFim == undefined) {
            setTo(undefined)
        }
    }, [props.valorFim]);

    const resetDates = () => {
        setFrom(null);
        setTo(null);
        setManipuladoPeloUsuario(false);
        setManipuladoPeloUsuarioTo(false);
        props.onChange(props.name, { from: null, to: null });
    }

    const isValid = (final) => {
        if (final) {
            return ((manipuladoPeloUsuarioTo || props.submit) && props.invalido && !to);
        } else {
            return ((manipuladoPeloUsuario || props.submit) && props.invalido && !from);
        }
    }

    const changeClass = (final = false) => {
        if (isValid(final)) {
            return {
                width: "100%",
                border: "1px solid red",
                padding: ".375rem .75rem",
                boxSizing: "border-box",
                borderRadius: '0.7rem',
                height: 'calc(1.5em + .75rem)',
            };
        } else {
            return {
                width: "100%",
                border: "1px solid #ced4da",
                padding: ".375rem .75rem",
                boxSizing: "border-box",
                borderRadius: '0.7rem',
                height: 'calc(1.5em + .75rem)',
            };
        }
    }

    const onChange = (tipo, valor) => {
        if (tipo === "data_inicial") {
            if (props.dataFinal) {
                setFrom(valor);
                setTo(props.dataFinal.intervalo ? undefined : to);
                const toFormated = props.dataFinal.intervalo ? undefined : formatDate(to, formatLaravelDate);
                props.onChange(props.name, { from: formatDate(valor, formatLaravelDate), to: toFormated });
            } else {
                setFrom(valor);
                props.onChange(props.name, formatDate(valor, formatLaravelDate));
            }
        } else {
            setTo(valor);
            props.onChange(props.name, { from: formatDate(from, formatLaravelDate), to: formatDate(valor, formatLaravelDate) });
        }
    }

    const modifiers = { start: from, end: to };
    const diasFuturos = props.dataInicial.diasFuturos ? undefined : new Date();
    const dayPickerProps = props.dataFinal
        ? { selectedDays: [from, { from, to }], disabledDays: { after: to }, modifiers, onDayClick: () => !to && toRef.current.getInput().focus() }
        : { disabledDays: { after: diasFuturos } };

    const toRef = React.createRef();

    return (
        <Fragment>
            <Col {...props.dataInicial.style.col} className="form-col">
                <FormGroup className={(props.hidden ? "hidden" : "")}>
                    <Label className={props.dataInicial.style.labelClass + " label"}>
                        {props.dataInicial.iconLabel && <FaRegCalendarAlt size="18px" style={{ marginRight: "10px" }} />}
                        {props.dataInicial.label + ":"}
                    </Label>
                    <DayPickerInput
                        placeholder={props.dataInicial.placeholder}
                        className={`${telaAvaliacao ? "tela-avaliacao" : ""}`}
                        name={props.dataInicial.name ? props.dataInicial.name : `${dateFnsFormat(new Date(), formatoData)}`}
                        value={from}
                        format={formatoData}
                        formatDate={formatDate}
                        readOnly={props.dataInicial.readOnly}
                        parseDate={parseDate}
                        onDayChange={(day) => onChange("data_inicial", day)}
                        dayPickerProps={{ locale: 'pt-br', ...pt_br, ...dayPickerProps }}
                        inputProps={{
                            onBlur: () => setManipuladoPeloUsuario(false),
                            style: changeClass(),
                            disabled: props.dataInicial.readOnly
                        }}
                    />
                    <FormFeedback style={{ display: (isValid()) ? "block" : "none" }}>
                        {"Selecione uma opção valida !"}
                    </FormFeedback>
                </FormGroup>
            </Col>

            {props.dataFinal && (
                <Col {...props.dataFinal.style.col} className="form-col">
                    <FormGroup className={(props.hidden ? "hidden" : "")}>
                        <Label className={props.dataFinal.style.labelClass + " label"}>
                            {props.dataFinal.label + ":"}
                        </Label>
                        <DayPickerInput
                            ref={toRef}
                            value={to}
                            placeholder={props.dataFinal.placeholder}
                            months={pt_br.months}
                            formatDate={formatDate}
                            format={formatoData}
                            parseDate={parseDate}
                            onDayChange={(day) => onChange("data_final", day)}
                            inputProps={{
                                onBlur: () => setManipuladoPeloUsuarioTo(true),
                                style: changeClass(true),
                                disabled: props.dataFinal.readOnly
                            }}
                            dayPickerProps={{
                                locale: 'pt-br',
                                selectedDays: [from, { from, to }],
                                disabledDays: {
                                    before: from,
                                    after: props.dataFinal.intervalo ? addDays(from, props.dataFinal.intervalo) : undefined
                                },
                                modifiers,
                                month: from,
                                fromMonth: from,
                                ...pt_br
                            }}
                        />
                        <FormFeedback style={{ display: (isValid(true)) ? "block" : "none" }}>
                            Selecione uma opção valida !
                        </FormFeedback>
                    </FormGroup>
                </Col>
            )}
        </Fragment>
    );
}

export default DateForm;

const colShape = {
    xs: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    md: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    lg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    xl: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

const styleShape = {
    labelClass: PropTypes.string,
    elementoClass: PropTypes.string,
    col: PropTypes.shape(colShape)
};

const dataShape = {
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    intervalo: PropTypes.number,
    style: PropTypes.shape(styleShape)
};

const style = {
    labelClass: "label-conteudoInferior",
    elementoClass: "",
    col: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12
    }
};

DateForm.propTypes = {
    name: PropTypes.string.isRequired,
    innerRef: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    dataInicial: PropTypes.shape(dataShape).isRequired,
    dataFinal: PropTypes.shape(dataShape)
};

DateForm.defaultProps = {
    placeholder: "",
    dataInicial: {
        style: {}
    },
};
