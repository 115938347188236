import React, { Component, Fragment } from 'react'
import { Col, Row } from 'reactstrap'
import { LoginForm, RecuperarSenhaForm, MudarSenhaForm, Loader } from '../Basicos'
import { connect } from 'react-redux'
import { Route, withRouter } from "react-router-dom"
import * as actionsRedux from '../Store/Actions/index'

class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            formulario: {},
            invalido: [],
            submit: false,
            path: this.props.location.pathname,
            token: this.getTokenFromURL() // Adicionar estado para armazenar o token da URL
        }
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {
        const axiosBaseURL = process.env.REACT_APP_AXIOS_BASE_URL;

        if (axiosBaseURL !== 'https://pedagogia-unichristus.softbuilder.com.br/api') {
            if (nextProps.location.pathname !== prevState.path) {
                const searchParams = new URLSearchParams(nextProps.location.search);
                const token = searchParams.get('token'); // Obtém o token da URL
                return {
                    formulario: { usuario: prevState.formulario.usuario },
                    submit: false,
                    path: nextProps.match.path,
                    token: token, // Atualiza o estado com o token
                };
            } else {
                return null;
            }
        }
        return null;
    };

    componentDidMount = () => {
        this.verificarPreechimento();
        const axiosBaseURL = process.env.REACT_APP_AXIOS_BASE_URL;

        if (axiosBaseURL === 'https://pedagogia-unichristus.softbuilder.com.br/api') {
            if (this.state.token) {
                this.props.autenticarComToken(this.state.token);
            } else {
                window.location.href = 'https://portalunichristussb.azurewebsites.net/';
                return null;
            }
        }

    }

    getTokenFromURL() {
        const params = new URLSearchParams(window.location.search);
        return params.get('token');
    }

    verificarPreenchimentoCampo = (nome) => {
        if (nome === 'senha_confirmation' && this.state.formulario[nome] !== this.state.formulario['senha'])
            return true
        else if (this.state.formulario[nome] === undefined || this.state.formulario[nome] === "")
            return true
        else
            return false
    }

    verificarPreechimento = () => {
        let invalido = [];
        let aux = this.verificarPreenchimentoCampo('usuario');
        invalido.push(aux);
        aux = this.verificarPreenchimentoCampo('senha');
        invalido.push(aux);
        aux = this.verificarPreenchimentoCampo('senha_confirmation');
        invalido.push(aux);
        this.setState({ invalido: invalido });
    }

    onChange = (nome, valor) => {
        this.setState((prevState) => {
            return {
                formulario: {
                    ...prevState.formulario,
                    [nome]: valor
                }
            }
        }, this.verificarPreechimento)
    }

    submit = () => {
        let formulario = Object.assign({}, this.state.formulario)
        let invalido = this.state.invalido.slice()
        let formularioIncompleto = (invalido[0] || invalido[1])
        // console.log(formularioIncompleto)
        if (!formularioIncompleto) {
            this.props.autenticar(formulario.usuario, formulario.senha)
            this.setState({
                formulario: { usuario: formulario.usuario, senha: '' },
                invalido: [false, true, true],
                submit: true
            })
        } else {
            this.setState({ submit: true })
        }
    }

    submitRecuperarSenha = () => {
        let formulario = Object.assign({}, this.state.formulario)
        if (!this.state.invalido[0]) {
            this.props.recuperarSenha(formulario.usuario, this.props.history);
            this.setState({
                formulario: { usuario: formulario.usuario, senha: '' },
                invalido: [false, true, true],
                submit: true
            })
        } else {
            this.setState({ submit: true })
        }
    }

    submitMudarSenha = (token) => {
        let formulario = Object.assign({}, this.state.formulario)
        let invalido = this.state.invalido.slice()
        let formularioIncompleto = (invalido[0] || invalido[1] || invalido[2])
        if (!formularioIncompleto) {
            this.props.mudarSenha(formulario.usuario,
                formulario.senha,
                formulario.senha_confirmation,
                token,
                this.props.history
            );
            this.setState({
                formulario: { usuario: formulario.usuario, senha: '', senha_confirmation: '' },
                invalido: [false, true, true],
                submit: true
            })
        } else {
            this.setState({ submit: true })
        }
    }

    render() {
        return (
            <Row className={`h-100vh ${process.env.REACT_APP_AXIOS_BASE_URL !== 'https://pedagogia-unichristus.softbuilder.com.br/api' ? 'w-100vh' : ''}`} style={{ backgroundColor: "var(--cor-principal)" }} noGutters>
                {console.log(process.env.REACT_APP_AXIOS_BASE_URL !== 'https://pedagogia-unichristus.softbuilder.com.br/api')}
                {process.env.REACT_APP_AXIOS_BASE_URL !== 'https://pedagogia-unichristus.softbuilder.com.br/api' && (<Col xl={4} lg={4} md={5} sm={6} xs={0} className="d-none d-sm-block">
                    <svg width="100%" height="100%" viewBox="0 0 665 925" preserveAspectRatio="none" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_d)">
                            <path d="M0 0H413.298C518.784 0 604.298 85.5136 604.298 191V734C604.298 839.486 518.784 925 413.298 925H0V0Z" fill="white" />
                        </g>
                        <path d="M273.317 495.557V359.195H273.263C273.288 358.113 273.317 357.033 273.317 355.941C273.317 333.974 268.079 313.878 259.413 298.348C259.393 298.306 259.378 298.265 259.358 298.223C230.705 237.25 156.255 242.657 156.255 242.657V353.789C156.255 353.789 156.282 353.79 156.291 353.79C156.28 354.507 156.255 355.22 156.255 355.941C156.255 357.032 156.284 358.113 156.31 359.195H156.255V495.557C156.255 495.557 153.452 592.128 260.78 592.128C273.26 592.128 284.272 592.128 294.019 592.128C368.09 592.128 368.107 592.128 368.107 592.128C273.995 571.559 273.317 495.557 273.317 495.557Z" fill="#19385D" />
                        <path d="M448.002 494.956V358.594H447.948C447.973 357.513 448.002 356.432 448.002 355.34C448.002 354.619 447.977 353.907 447.966 353.189C447.975 353.189 448.002 353.188 448.002 353.188V242.056C448.002 242.056 373.552 236.65 344.899 297.622C344.878 297.665 344.864 297.706 344.843 297.749C336.178 313.278 330.94 333.373 330.94 355.34C330.94 356.432 330.969 357.513 330.994 358.594H330.94V494.956C330.94 494.956 330.662 516.181 321.652 531.199C321.381 531.65 321.193 532.104 321.066 532.561C319.049 536.396 314.684 543.978 307.835 552.224C307.835 552.224 326.858 576.253 373.713 588.667C374.758 588.979 375.758 588.82 376.417 588.667C452.407 571.047 448.002 494.956 448.002 494.956Z" fill="#19385D" />
                        <path d="M173.76 671.104C174.181 669.234 174.453 667.292 174.468 665.38C174.542 656.057 174.507 646.734 174.506 637.411C174.506 637.136 174.463 636.861 174.437 636.542C171.864 636.542 169.358 636.542 166.708 636.542C166.708 637.061 166.708 637.514 166.708 637.967C166.708 646.719 166.712 655.47 166.704 664.222C166.703 666.047 166.551 667.858 166.113 669.64C165.4 672.549 163.587 674.392 160.669 675.15C158.926 675.602 157.163 675.6 155.42 675.159C152.321 674.374 150.305 672.517 149.577 669.36C149.149 667.503 148.961 665.624 148.957 663.731C148.939 655.087 148.949 646.442 148.947 637.798C148.947 637.384 148.919 636.97 148.904 636.539C145.871 636.539 142.964 636.539 139.928 636.539C139.928 636.965 139.928 637.25 139.928 637.534C139.928 646.178 139.905 654.823 139.947 663.467C139.956 665.245 140.123 667.033 140.364 668.797C140.708 671.305 141.512 673.655 143.224 675.626C144.933 677.595 146.994 679.085 149.468 679.912C154.715 681.664 160.054 681.895 165.306 680.099C169.651 678.613 172.727 675.693 173.76 671.104Z" fill="#1C4F82" />
                        <path d="M296.888 679.122C296.89 671.622 296.909 664.123 296.867 656.623C296.86 655.496 296.653 654.347 296.374 653.249C295.988 651.736 295.213 650.411 293.965 649.397C290.698 646.743 287.066 646.583 283.261 647.931C280.731 648.828 278.833 650.562 277.276 652.691C277.041 653.013 276.901 653.452 276.257 653.659C276.255 651.917 276.253 650.293 276.252 648.67C276.251 646.992 276.252 645.313 276.252 643.635C276.252 641.992 276.254 640.349 276.251 638.706C276.249 637.042 276.262 635.376 276.242 633.66C273.318 633.66 270.554 633.66 267.737 633.66C267.507 635.031 267.561 679.216 267.783 680.069C270.558 680.069 273.347 680.069 276.252 680.069C276.252 679.571 276.252 679.15 276.252 678.73C276.252 672.73 276.246 666.73 276.267 660.731C276.268 660.255 276.328 659.682 276.593 659.322C277.439 658.172 278.329 657.037 279.327 656.016C280.536 654.778 282.077 654.065 283.815 653.843C286.002 653.564 287.747 654.864 288.052 657.02C288.172 657.865 288.211 658.727 288.213 659.582C288.225 666.01 288.219 672.438 288.223 678.866C288.223 679.276 288.265 679.685 288.291 680.149C291.226 680.118 294.022 680.213 296.842 680.089C296.862 679.687 296.888 679.405 296.888 679.122Z" fill="#1C4F82" />
                        <path d="M421.976 647.898C421.976 648.39 421.976 648.811 421.976 649.232C421.976 655.266 421.984 661.299 421.962 667.333C421.961 667.808 421.895 668.382 421.627 668.738C420.747 669.908 419.826 671.062 418.796 672.101C417.716 673.19 416.337 673.844 414.804 674.108C412.247 674.548 410.408 673.164 410.148 670.602C410.068 669.823 410.023 669.036 410.022 668.253C410.012 661.898 410.016 655.543 410.016 649.188C410.016 648.772 410.016 648.356 410.016 647.832C407.04 647.833 404.218 647.796 401.371 647.856C401.348 648.288 401.32 648.57 401.32 648.852C401.317 656.171 401.309 663.491 401.329 670.809C401.332 671.733 401.425 672.667 401.587 673.577C402.274 677.419 404.927 680.045 408.795 680.727C410.981 681.113 413.102 680.827 415.16 680.02C417.7 679.023 419.59 677.262 421.151 675.098C421.341 674.835 421.42 674.425 422.007 674.379C422.007 676.302 422.007 678.177 422.007 680.087C424.94 680.087 427.738 680.087 430.521 680.087C430.783 678.951 430.735 648.639 430.487 647.898C427.686 647.898 424.889 647.898 421.976 647.898Z" fill="#1C4F82" />
                        <path d="M212.149 657.537C212.146 656.577 212.067 655.611 211.933 654.66C211.308 650.234 208.287 647.901 204.718 647.297C200.899 646.651 197.487 647.72 194.641 650.333C193.75 651.151 193.043 652.164 192.246 653.084C192.082 653.274 191.893 653.443 191.715 653.621C191.633 653.591 191.551 653.561 191.469 653.531C191.469 651.665 191.469 649.799 191.469 647.828C188.489 647.848 185.668 647.764 182.86 647.882C182.611 650.903 182.732 679.299 182.991 680.095C185.754 680.095 188.522 680.095 191.478 680.095C191.478 679.515 191.478 679.056 191.478 678.597C191.478 672.67 191.47 666.743 191.493 660.817C191.495 660.307 191.564 659.695 191.848 659.307C192.715 658.129 193.634 656.971 194.66 655.929C195.821 654.751 197.315 654.1 198.966 653.855C201.051 653.546 202.802 654.708 203.225 656.726C203.377 657.452 203.411 658.213 203.413 658.958C203.427 665.563 203.421 672.168 203.421 678.773C203.421 679.191 203.421 679.608 203.421 680.074C206.392 680.074 209.218 680.074 212.158 680.074C212.158 679.583 212.158 679.164 212.158 678.744C212.158 671.675 212.167 664.606 212.149 657.537Z" fill="#1C4F82" />
                        <path d="M462.28 675.946C465.335 671.881 464.452 666.065 459.974 663.584C458.685 662.871 457.38 662.173 456.031 661.587C454.249 660.813 452.414 660.16 450.605 659.448C449.626 659.062 448.699 658.587 447.961 657.819C446.822 656.632 446.804 654.905 448.018 653.802C448.531 653.335 449.2 652.902 449.864 652.765C451.051 652.52 452.289 652.407 453.503 652.426C455.831 652.461 458.054 653.113 460.262 653.792C460.626 653.904 460.993 654.003 461.426 654.127C461.426 652.108 461.426 650.224 461.426 648.286C461.052 648.194 460.71 648.089 460.359 648.027C459.295 647.839 458.231 647.645 457.161 647.496C454.04 647.063 450.91 646.947 447.785 647.426C445.582 647.764 443.537 648.495 441.763 649.901C437.623 653.182 437.058 660.552 442.503 663.828C443.794 664.605 445.124 665.331 446.49 665.969C447.89 666.622 449.376 667.095 450.786 667.73C451.831 668.2 452.899 668.692 453.808 669.369C455.645 670.74 455.584 673.143 453.698 674.456C453.012 674.934 452.167 675.299 451.345 675.447C450.296 675.636 449.189 675.652 448.121 675.568C445.148 675.335 442.322 674.475 439.555 673.412C439.209 673.279 438.853 673.174 438.445 673.036C438.445 675.156 438.445 677.109 438.445 679.1C438.743 679.208 439.002 679.332 439.275 679.395C440.574 679.695 441.867 680.03 443.179 680.257C446.855 680.895 450.549 681.179 454.264 680.564C457.522 680.025 460.269 678.62 462.28 675.946Z" fill="#1C4F82" />
                        <path d="M368.393 663.671C367.213 662.985 366.006 662.328 364.756 661.783C362.975 661.006 361.128 660.378 359.339 659.62C358.417 659.23 357.468 658.81 356.687 658.206C354.714 656.679 355.015 654.154 357.284 653.088C358.046 652.73 358.928 652.538 359.774 652.455C362.743 652.164 365.576 652.875 368.378 653.745C368.777 653.869 369.181 653.978 369.668 654.119C369.668 652.112 369.668 650.232 369.668 648.239C368.805 648.074 367.968 647.901 367.126 647.756C363.483 647.128 359.824 646.872 356.147 647.415C353.712 647.775 351.478 648.598 349.62 650.278C345.882 653.658 345.595 660.453 350.438 663.591C351.701 664.409 353.023 665.158 354.387 665.796C356.048 666.572 357.8 667.156 359.475 667.905C360.421 668.327 361.366 668.818 362.181 669.445C363.852 670.731 363.798 673.027 362.151 674.35C361.438 674.923 360.633 675.363 359.722 675.455C358.617 675.567 357.49 675.649 356.385 675.567C353.484 675.35 350.726 674.505 348.02 673.486C347.632 673.34 347.242 673.199 346.807 673.038C346.807 675.159 346.807 677.143 346.807 679.155C347.202 679.277 347.539 679.395 347.883 679.485C350.892 680.275 353.948 680.718 357.063 680.861C359.537 680.974 361.972 680.825 364.347 680.143C367.751 679.164 370.315 677.16 371.703 673.866C372.954 670.901 372.605 666.12 368.393 663.671Z" fill="#1C4F82" />
                        <path d="M261.259 673.745C259.225 674.47 257.134 674.973 254.97 675.168C250.51 675.57 246.616 673.926 244.87 669.098C244.192 667.221 243.974 665.294 244.012 663.312C244.058 661.015 244.661 658.886 245.741 656.863C247.223 654.087 249.574 652.814 252.698 652.807C255.254 652.801 257.697 653.41 260.126 654.105C260.484 654.207 260.845 654.302 261.276 654.42C261.276 652.332 261.276 650.416 261.276 648.379C256.585 647.206 251.966 646.662 247.269 647.572C241.492 648.691 237.574 652.027 235.751 657.545C234.101 662.54 234.294 667.551 236.547 672.36C238.737 677.037 242.443 679.8 247.634 680.603C251.105 681.14 254.534 680.859 257.956 680.257C259.119 680.052 260.268 679.764 261.371 679.525C261.616 678.037 261.546 674.499 261.259 673.745Z" fill="#1C4F82" />
                        <path d="M396.236 680.002C396.439 678.489 396.38 675.808 396.129 675.051C395.241 675.111 394.344 675.241 393.45 675.22C390.628 675.153 389.203 673.898 388.793 671.151C388.693 670.483 388.671 669.799 388.669 669.123C388.659 664.162 388.662 659.2 388.667 654.239C388.667 653.897 388.714 653.554 388.746 653.108C389.954 653.108 391.064 653.114 392.174 653.106C393.315 653.098 394.458 653.144 395.572 653.073C395.785 651.443 395.718 648.591 395.434 647.775C393.248 647.775 391.025 647.775 388.673 647.775C388.673 645.622 388.673 643.568 388.673 641.375C385.717 641.714 382.909 642.036 380.019 642.368C379.838 644.252 380.069 645.995 379.852 647.84C378.634 647.84 377.5 647.84 376.393 647.84C376.166 649.242 376.215 652.299 376.495 653.161C377.587 653.161 378.722 653.161 379.946 653.161C379.946 653.711 379.946 654.134 379.946 654.558C379.947 658.876 379.934 663.195 379.953 667.514C379.961 669.153 380.005 670.795 380.097 672.432C380.333 676.621 382.691 679.465 386.815 680.399C388.244 680.722 389.738 680.869 391.206 680.897C392.933 680.929 394.624 680.546 396.236 680.002Z" fill="#1C4F82" />
                        <path d="M325.86 654.782C325.86 652.248 325.86 649.771 325.86 647.252C323.343 646.912 321.055 647.321 318.933 648.572C317.163 649.614 315.754 651.02 314.665 652.749C314.476 653.051 314.386 653.472 313.819 653.614C313.819 651.683 313.819 649.845 313.819 647.893C310.85 647.893 308.057 647.893 305.216 647.893C305.216 658.649 305.216 669.338 305.216 680.075C308.079 680.075 310.87 680.075 313.778 680.075C313.778 679.585 313.778 679.165 313.778 678.746C313.778 672.819 313.8 666.891 313.757 660.964C313.75 659.968 313.998 659.153 314.642 658.422C314.832 658.207 314.996 657.971 315.186 657.756C317.628 654.985 320.647 653.764 324.36 654.439C324.851 654.528 325.334 654.66 325.86 654.782Z" fill="#1C4F82" />
                        <path d="M228.998 647.89C226.111 647.89 223.283 647.89 220.461 647.89C220.406 648.039 220.337 648.137 220.337 648.236C220.326 658.516 220.32 668.796 220.317 679.076C220.317 679.359 220.36 679.642 220.388 679.924C220.391 679.954 220.427 679.983 220.452 680.009C220.475 680.034 220.503 680.055 220.534 680.082C223.329 680.082 226.129 680.082 228.998 680.082C228.998 669.277 228.998 658.616 228.998 647.89Z" fill="#1C4F82" />
                        <path d="M330.369 647.888C330.124 651.032 330.243 679.285 330.504 680.114C333.266 680.114 336.038 680.114 338.871 680.114C339.147 678.899 339.092 648.647 338.811 647.824C336.007 647.861 333.18 647.753 330.369 647.888Z" fill="#1C4F82" />
                        <path d="M228.879 635.087C227.167 634.892 221.165 634.969 220.37 635.212C220.37 637.459 220.37 639.722 220.37 642.137C223.313 642.137 226.129 642.137 228.932 642.137C229.148 640.843 229.1 635.838 228.879 635.087Z" fill="#1C4F82" />
                        <path d="M330.383 635.189C330.383 637.501 330.383 639.806 330.383 642.143C333.26 642.143 336.058 642.143 338.898 642.143C339.135 640.771 339.081 635.844 338.85 635.089C337.182 634.893 331.1 634.969 330.383 635.189Z" fill="#1C4F82" />
                        <defs>
                            <filter id="filter0_d" x="-40" y="-48" width="704.298" height="1025" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                                <feOffset dx="10" dy="2" />
                                <feGaussianBlur stdDeviation="25" />
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0" />
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                            </filter>
                        </defs>
                    </svg>
                </Col>)}

                {process.env.REACT_APP_AXIOS_BASE_URL !== 'https://pedagogia-unichristus.softbuilder.com.br/api' ? (<Col xs={12} sm={6} md={7} lg={8} xl={8} className="align-content-middle">
                    {this.props.loader ? (
                        <Loader />
                    ) : (
                        <Fragment>
                            {process.env.REACT_APP_AXIOS_BASE_URL !== 'https://pedagogia-unichristus.softbuilder.com.br/api' && (<Route exact
                                path={'/login'}
                                render={() => (
                                    <LoginForm
                                        onChange={this.onChange}
                                        submitOnClick={this.submit}
                                        {...this.state}
                                    />
                                )}
                            />)}

                            <Route exact
                                path={'/login/recuperar-senha'}
                                render={() => <RecuperarSenhaForm
                                    onChange={this.onChange}
                                    submitOnClick={this.submitRecuperarSenha}
                                    {...this.state}
                                />}
                            />

                            <Route exact
                                path={'/login/mudar-senha/:token'}
                                render={(props) => <MudarSenhaForm
                                    onChange={this.onChange}
                                    validarRecuperacaoToken={this.props.validarRecuperacaoToken}
                                    submitOnClick={this.submitMudarSenha}
                                    tokenRecuperacaoValido={this.props.tokenRecuperacaoValido}
                                    loader={this.props.loader}
                                    {...this.state}
                                    {...props}
                                />}
                            />

                        </Fragment>
                    )}
                </Col>) : (this.props.loader ? (
                    <Loader />
                ) : (
                    <Fragment>

                        <Route exact
                            path={'/login/recuperar-senha'}
                            render={() => <RecuperarSenhaForm
                                onChange={this.onChange}
                                submitOnClick={this.submitRecuperarSenha}
                                {...this.state}
                            />}
                        />

                        <Route exact
                            path={'/login/mudar-senha/:token'}
                            render={(props) => <MudarSenhaForm
                                onChange={this.onChange}
                                validarRecuperacaoToken={this.props.validarRecuperacaoToken}
                                submitOnClick={this.submitMudarSenha}
                                tokenRecuperacaoValido={this.props.tokenRecuperacaoValido}
                                loader={this.props.loader}
                                {...this.state}
                                {...props}
                            />}
                        />

                    </Fragment>
                ))}
            </Row>
        )
    }
}


const mapStateToProps = (state) => ({
    loader: state.auth.loader,
    erro: state.auth.erro,
    tokenRecuperacaoValido: state.auth.tokenRecuperacaoValido
})

const mapDispatchToProps = (dispatch) => {
    return {
        ativarAlert: (tipo, acao) => dispatch(actionsRedux.ativarAlert(tipo, acao)),
        autenticar: (usuario, senha) => dispatch(actionsRedux.autenticar(usuario, senha)),
        autenticarComToken: (token) => dispatch(actionsRedux.autenticarComToken(token)),
        validarRecuperacaoToken: (token, history) => dispatch(actionsRedux.validarRecuperacaoToken(token, history)),
        recuperarSenha: (usuario, history) => dispatch(actionsRedux.recuperarSenha(usuario, history)),
        mudarSenha: (usuario, senha, senha_confirmation, token, history) => dispatch(actionsRedux.mudarSenha(usuario, senha, senha_confirmation, token, history))
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login))
